import axiosClient from "./axiosWrapper";

// EXAMPLE
// {
//   "template": "string",
//   "sender": "ROSGIDROMET",
//   "earthquakeDate": "2024-07-30T21:22:54.970Z",
//   "latitude": 0,
//   "longitude": 0,
//   "magnitude": 0
// }

export const getTemplates = async () => {
  let result = await axiosClient().get(`/expert/templates`)
  return result
}

export const parseMessage = async (messageId) => {
  let result = await axiosClient().post(`/expert/parse`, {messageId: messageId})
  return result
}

export const prepareFile = async (data) => {
  let result = await axiosClient().post(`/expert/prepare`, data)
  return result
}

export const sendDocs = async (data) => {
  let result = await axiosClient().post(`/expert/send`, data)
  return result
}